var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[_vm._l((_vm.schedule),function(ref){
var title = ref.title;
var crn = ref.crn;
var meets = ref.meets;
var meetingBase = ref.meetingBase;
var instructor = ref.instructor;
var regStatus = ref.regStatus;
var credits = ref.credits;
var gradeMode = ref.gradeMode;
return _c('v-list-item',{key:'course-'+crn},[(regStatus === 'WL')?_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fas fa-exclamation-triangle")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))]),_c('v-list-item-subtitle',[_c('b',{staticStyle:{"vertical-align":"top"}},[_vm._v("Meets: ")]),_c('span',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(meets || _vm.getMeetString(meetingBase))}})]),(instructor)?_c('v-list-item-subtitle',[_c('b',[_vm._v("Instructor: ")]),_vm._v(_vm._s(typeof (instructor) === 'object' ? instructor.name : instructor))]):_vm._e(),(regStatus === 'WL')?_c('v-list-item-subtitle',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.isEmployee ? 'Student is' : 'You are')+" currently wait-listed for this course")]):_vm._e(),(credits)?_c('v-list-item-subtitle',[_vm._v("Credits: "+_vm._s(credits))]):_vm._e(),(gradeMode && gradeMode === 'pass-fail')?_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"label":""}},[_vm._v("Grade Mode is Pass/Fail")])],1):_vm._e()],1)],1)}),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Total credit hours: "+_vm._s(_vm.credits))]),(_vm.updatedAt)?_c('v-list-item-subtitle',[_vm._v("Last Updated from Banner: "+_vm._s(_vm.updatedAt))]):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }